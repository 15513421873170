<template>
    <div class="dashboard-logged mainWrapper bg-white">
       <section class="blockElement innerMenu py-3 pt-5 bg-white">
           <div class="container max-1370">
               <div>
                   <h3 class="text-center">{{$t('newInvestror.newText1')}}</h3>
                   <p class="text-center">{{$t('newInvestror.newText2')}}</p>
                   <div class="filter-box">
                       <div class="col-12"><p>{{$t('newInvestror.newText3')}}</p></div>
                       <div class="d-flex ">
                           <div class="col-12 col-md-6">
                               <ul id="tabs-nav" class="toggleSwitch-layout iconButton d-flex h-auto align-items-center me-3 p-0 justify-content-start">
                                   <li class="d-flex">
                                        <a @click.prevent="zuluGrid = 'tab1'" :class="(zuluGrid == 'tab1')?'active':''" href="javascript:void(0)" class="switchToggle left">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                        </a>
                                    </li>
                                    <li class="d-flex">
                                        <a @click.prevent="zuluGrid = 'tab2'" :class="(zuluGrid == 'tab2')?'active':''"  href="javascript:void(0)" class="switchToggle right"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="15" x2="21" y2="15"></line><line x1="3" y1="6" x2="21" y2="6"></line></svg>
                                        </a>
                                    </li>
                               </ul>
                           </div>
                       </div>
                   </div>
                   </div>
               </div>
       </section>
       <section class="blockElement space innerMenu pt-2 bg-white">
           <div class="container max-1370">
               <div class="row">
                    <div class="col-12">
                        <div id="tab1" class="tab-content table-responsive zuluTableBroker" v-if="zuluGrid == 'tab1'">
                            <table class="table table-bordered mb-0 investors-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{{$t('newInvestror.newText4')}}</th>
                                        <th>{{$t('newInvestror.newText5')}}</th>
                                        <th>{{$t('newInvestror.newText6')}}</th>
                                        <th>{{$t('newInvestror.newText7')}}</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <a href="https://fxview.com/?refLink=MzI5&refRm=NDkw" target="_blank"><v-lazy-image class="darkMode" src="/assets/images/fxview-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText9')" /></a>
                                            </div>
                                        </td>
                                        <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{$t('newInvestror.newText10')}}</td>
                                        <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{$t('newInvestror.newText11')}}</td>
                                        <td :class="(taskTab == '1')?'hideActive':''"  class="text-center">{{$t('newInvestror.newText12')}}</td>
                                        <td :class="(taskTab == '1')?'hideActive':''" class="text-center">{{$t('newInvestror.newText13')}}</td>
                                        <td class="text-center">
                                            <button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" type="button">{{$t('newInvestror.newText14')}}</button>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://secure.activtrades.com/?ib=2b566fa42754489fb075236c3f667c03&lang=en" target="_blank"><v-lazy-image class="darkMode" width="85" height="17" src="/assets/images/clientLogo/activTrade_logo.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText15')" /></a>
                                        </div>
                                        <a class="text-left brand-link" target="_blank"
                                            href="https://www.activtrades.com/">{{$t('newInvestror.newText16')}}</a>
                                    </td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText17')}}</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText18')}}</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText19')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="javascript:void(0)"><v-lazy-image class="darkMode" width="45" src="/assets/images/tickmill_1.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText15')" /></a>
                                        </div>
                                    </td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://go.libertex.com/visit/?bta=49034&nci=7622" target="_blank"><v-lazy-image class="darkMode" width="90" src="/assets/images/clientLogo/libertex_logo.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText15')" /></a>
                                        </div>
                                    </td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://www.deltastock.com/english/platforms/MT5-zulu.asp?mktg=zulu&acs=%D0%9C47747" target="_blank"><v-lazy-image class="darkMode" width="90" src="/assets/images/clientLogo/deltaStock.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText15')" /></a>
                                        </div>
                                    </td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText20')}}</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">-</td>
                                    <td :class="(taskTab == '2')?'hideActive':''" class="text-center">{{$t('newInvestror.newText21')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://go.blackbullmarkets.com/visit/?bta=39889&nci=5758" target="_blank"><v-lazy-image class="darkMode" src="/assets/images/clientLogo/blackBull_logo.png" width="80" height="22" :alt="$t('newInvestror.newText8')" title="BlackBull" /></a>
                                        </div>
                                        <a class="text-left brand-link" target="_blank"
                                            href="https://blackbullmarkets.com/">{{$t('newInvestror.newText22')}}</a>
                                    </td>
                                    <td :class="(taskTab == '3')?'hideActive':''"  class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '3')?'hideActive':''" class="text-center">{{$t('newInvestror.newText23')}}</td>
                                    <td :class="(taskTab == '3')?'hideActive':''" class="text-center">{{$t('newInvestror.newText24')}}</td>
                                    <td :class="(taskTab == '3')?'hideActive':''" class="text-center">{{$t('newInvestror.newText25')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://www.finveo.mn/en/open-an-account?utm_source=zulutrade&utm_campaign=affiliate&utm_medium=socialtrading" target="_blank"><v-lazy-image class="darkMode" src="/assets/images/finveo-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText26')" /></a>
                                        </div>
                                        <a class="text-left brand-link" target="_blank"
                                            href="https://www.finveo.mn/">www.finveo.mn/</a>
                                    </td>
                                    <td :class="(taskTab == '4')?'hideActive':''"  class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '4')?'hideActive':''" class="text-center">{{$t('newInvestror.newText27')}}</td>
                                    <td :class="(taskTab == '4')?'hideActive':''" class="text-center">{{$t('newInvestror.newText28')}}</td>
                                    <td :class="(taskTab == '4')?'hideActive':''" class="text-center">{{$t('newInvestror.newText29')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://live.itcscapital.com/signup/ZuluTrade/K77FnRHk" target="_blank"><v-lazy-image class="darkMode" src="/assets/images/ITCS-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText30')" /></a>
                                        </div>
                                        <a class="text-left brand-link" target="_blank" href="https://itcs.trade/">{{$t('newInvestror.newText31')}}</a>
                                    </td>
                                    <td :class="(taskTab == '5')?'hideActive':''" class="text-center">{{$t('newInvestror.newText32')}}</td>
                                    <td :class="(taskTab == '5')?'hideActive':''" class="text-center">{{$t('newInvestror.newText29')}}</td>
                                    <td :class="(taskTab == '5')?'hideActive':''" class="text-center">{{$t('newInvestror.newText29')}}</td>
                                    <td :class="(taskTab == '5')?'hideActive':''" class="text-center">{{$t('newInvestror.newText29')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div
                                            class="d-flex justify-content-between align-items-center pointer brand-logo">
                                            <a href="https://www.monetamarkets.com/open-live-account/?affid=43656" target="_blank"><v-lazy-image class="darkMode" src="/assets/images/MONT-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText36')" /></a>
                                        </div>
                                        <a class="text-left brand-link" target="_blank"
                                            href="https://www.monetamarkets.com/">{{$t('newInvestror.newText37')}}</a>
                                    </td>
                                    <td :class="(taskTab == '6')?'hideActive':''"  class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '6')?'hideActive':''" class="text-center">{{$t('newInvestror.newText38')}}</td>
                                    <td :class="(taskTab == '6')?'hideActive':''" class="text-center">{{$t('newInvestror.newText39')}}</td>
                                    <td :class="(taskTab == '6')?'hideActive':''" class="text-center">{{$t('newInvestror.newText40')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="https://www.vantagefx.com/get-trading/open-live/?affid=5052" target="_blank"><v-lazy-image class="darkMode" src="/assets/images/vantage-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText41')" /></a>
                                        </div>
                                        <a class="text-left brand-link" target="_blank"
                                            href="https://www.vantagefx.com">{{$t('newInvestror.newText42')}}</a>
                                    </td>
                                    <td :class="(taskTab == '7')?'hideActive':''" class="text-center">{{$t('newInvestror.newText10')}}</td>
                                    <td :class="(taskTab == '7')?'hideActive':''" class="text-center">{{$t('newInvestror.newText43')}}</td>
                                    <td :class="(taskTab == '7')?'hideActive':''" class="text-center">{{$t('newInvestror.newText44')}}</td>
                                    <td :class="(taskTab == '7')?'hideActive':''" class="text-center">{{$t('newInvestror.newText45')}}</td>
                                    <td class="text-center"><button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)"
                                            type="button">{{$t('newInvestror.newText14')}}</button>
                                    </td>
                                </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <a href="https://v1.aaafx.com/sign-up?t=bnVsbHxudWxsfG51bGx8bnVsbHxudWxsfG51bGx8bnVsbA%3D%3D&p=zulutrade" target="_blank"><v-lazy-image class="darkMode" width="80" src="/assets/images/clientLogo/AAAFx_logo.png" :alt="$t('newInvestror.newText8')" title="AAAfx" /></a>
                                            </div>
                                            <a class="text-left brand-link" target="_blank"
                                                href="https://aaafx-international.com/">{{$t('newInvestror.newText46')}}</a>
                                        </td>
                                        <td :class="(taskTab == '8')?'hideActive':''" class="text-center">{{$t('newInvestror.newText47')}}</td>
                                        <td :class="(taskTab == '8')?'hideActive':''" class="text-center">{{$t('newInvestror.newText48')}}</td>
                                        <td :class="(taskTab == '8')?'hideActive':''" class="text-center">{{$t('newInvestror.newText49')}}</td>
                                        <td :class="(taskTab == '8')?'hideActive':''" class="text-center">{{$t('newInvestror.newText50')}}</td>

                                        <td class="text-center">
                                            <button class="button fillBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" type="button">{{$t('newInvestror.newText14')}}</button>
                                        </td>

                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <div id="tab2" class="tab-content" v-if="zuluGrid == 'tab2'">
                            <div class="invester-broker-list">
                                <ul>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/fxview-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText9')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/tickmill_1.png" width="40" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText51')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/clientLogo/libertex_logo.png" :alt="$t('newInvestror.newText52')" :title="Fxview" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image width="100" src="/assets/images/clientLogo/deltaStock.png" :alt="$t('newInvestror.newText53')" :title="Fxview" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/clientLogo/activTrade_logo.png" width="85" height="17" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText54')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/clientLogo/blackBull_logo.png" width="80" height="22" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText55')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/finveo-small.png" :alt="$t('newInvestror.newText8')" :title="Finveo" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/ITCS-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText56')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/MONT-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText57')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/vantage-small.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText58')" />
                                    </li>
                                    <li class="darkBG" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="javascript:void(0)" >
                                        <v-lazy-image src="/assets/images/clientLogo/AAAFx_logo.png" :alt="$t('newInvestror.newText8')" :title="$t('newInvestror.newText59')" />
                                    </li>
                                </ul>
                                </div>
                        </div>
                    </div>
               </div>
           </div>
       </section>
       <section class="blockElement pt-0 space bg-white">
        <div class="container">
            <div class="row justify-content-center">
                 <div class="col-12 col-md-6">
                    <div class="cantFind d-flex align-items-center justify-content-between flex-wrap">
                        <h3 class="mb-0">{{$t('newInvestror.newText60')}} </h3>
                        <button class="button fillBtn py-2 px-4" type="button" @click="raiseQuery = true">{{$t('newInvestror.newText61')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

   </div>
<!-- MODAL START View IFRAME -->
<div class="modal fade" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
   <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content">
           <div class="modal-header">
               <p class="mb-0 f-20 fw-300">{{$t('newInvestror.newText62')}}</p>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div class="modal-body row">
               <p class="text-center">{{$t('newInvestror.newText63')}}</p>
           </div>
           <div class="d-flex align-items-center justify-content-end px-3 pb-3">
               <a :href="'/register/'" type="button" class="button fillBtn f-14">{{$t('newInvestror.newText64')}}</a>
           </div>
       </div>
   </div>
</div>
<!-- End -->
<div class="modal fade muySellModal" :class="[{'show' : raiseQuery}]">
    <div class="modal-dialog modal-dialog-centered personalDetails">
        <div class="modal-content">
            <div class="modal-header border-0">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="toggleSlidebutton d-inline-flex align-items-center justify-content-center">
                        <h6 class="bold mb-0">{{$t('newInvestror.newText65')}}</h6>
                    </div>
                </div>
                <button type="button" class="btn-close" @click="raiseQuery = false"></button>
            </div>
            <div class="modal-body pb-2">
                <div class="specificPrice">
                     <div class="investmentAmount my-2">
                            <div class="form-group position-relative">
                                 <Form @submit="raiseQueryFunc()">
                                    <float-label class="countrySelect form-group position-relative" :class="[{'selected' : query.broker}]">
                                        <Field
                                          class="form-control"
                                          name="broker name"
                                          :placeholder="$t('newInvestror.newText66')"
                                          v-model="query.broker"
                                          rules="required"
                                          autocomplete="off"
                                        />
                                        <ErrorMessage name="broker name" class="text-danger" />
                                    </float-label>
                                    <float-label class="countrySelect form-group position-relative" :class="[{'selected' : query.brokerUrl}]">
                                        <Field
                                          class="form-control"
                                          name="broker url"
                                          :placeholder="$t('newInvestror.newText67')"
                                          v-model="query.brokerUrl"
                                          autocomplete="off"
                                        />
                                    </float-label>
                                     <float-label class="countrySelect form-group position-relative" :class="[{'selected' : query.info}]">
                                        <Field
                                            type="textarea"
                                          class="form-control"
                                          name="additional info"
                                          :placeholder="$t('newInvestror.newText68')"
                                          v-model="query.info"
                                          autocomplete="off"
                                        />
                                    </float-label>
                                    <div class="buttonElement mb-3">
                                        <button type="submit" class="button fillBtn py-2 px-4">{{$t('newInvestror.newText69')}}</button>
                                    </div>
                                </Form>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <loginPopup v-if="showLoginPopup"></loginPopup> -->
</template>
<script>
import { myStore } from "@/store/pinia-store";
import FloatLabel from 'vue-float-label/components/FloatLabel'
import { Form, Field, ErrorMessage } from "vee-validate";
   export default {
    setup() {
        const store = myStore();
        return { store };
    },
       data(){
           return {
               // showLoginPopup: false,
               taskTab:'',
               zuluGrid:'tab1',
               raiseQuery : false,
                query : {
                    broker : '',
                    info : '',
                    brokerUrl : ''
                }
           }
       },
       components: {
            FloatLabel,
            Form,
            Field,
            ErrorMessage,
        },
        methods:{
            raiseQueryFunc(){
            let form = {
                'name' : this.query.broker
            }
            if(this.query.brokerUrl){
                form['url'] = this.query.brokerUrl
            }
            if(this.query.info){
                form['additionalInfo'] = this.query.info
            }
            this.store.brokerRaiseQuery(form,true).then(response =>{
                if(response === true){
                    this.raiseQuery = false
                    this.query = {
                        broker : '',
                        info : '',
                        brokerUrl : ''
                    }
                }
            })
        },
        }
   }
</script>